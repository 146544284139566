@import "../../styles/theme.scss";

.Benchmarking {
  &--loading {
    padding: 0.5rem 1rem 1rem 1rem;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-evenly;

  &__title {
    margin-bottom: 0.5rem;
  }

  &__scoreSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    min-height: 138px;
    text-align: center;
  }

  &__scoreBubble {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 106px;
    width: 106px;
    border-radius: 50%;
    margin-top: 1rem;

    &__info {
      text-align: center;
      padding: 0.5rem;
    }
  }
}
