@import "../../styles/theme.scss";

.TrainingModuleOverview {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    position: sticky;
    top: 0;
    z-index: 98;
    width: 100%;
    height: 100px;
    &__thumbnail {
      width: inherit;
      height: inherit;
    }
  }

  &__content {
    flex: 1;
    overflow-y: auto;
    padding: 20px;

    &__title {
      word-wrap: break-word;
      padding-bottom: 1rem;

      &--capitalize {
        text-transform: capitalize;
      }
    }

    &__tags {
      margin-top: 0.5rem;
      margin-bottom: 1.5rem;

      .Chip {
        margin-right: 0.5rem;
        margin-top: 0.5rem;
      }
    }

    &__info {
      display: flex;
      margin-bottom: 0.5rem;
      gap: 0.5rem;
    }

    &__languages {
      display: flex;
      margin-bottom: 0.5rem;

      &__icon {
        margin-right: 0.5rem;
        color: $secondaryColor;
      }

      &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }

      &__text {
        margin-right: 0.5rem;
      }
    }
  }

  &__footer {
    position: sticky;
    bottom: 0;
    z-index: 98;
    background-color: white;
    border-radius: 0rem 0rem 1rem 1rem;

    display: flex;
    justify-content: space-between;
    padding: 1rem;
    text-align: center;

    &__link {
      text-decoration: none;
    }

    &__download {
      padding: 1rem;
      .Icon {
        margin-right: 0.5rem;
      }
    }
  }
}
