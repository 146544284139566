@import "../../styles/theme.scss";

.SurveyLaunchJobRoles {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  &__subtitle {
    margin-bottom: 2rem;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding-top: 2rem;
  }
}

.SurveyLaunchJobRolesItem {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.5rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid $grey400;
  margin-top: 0.5rem;

  &:last-child {
    border-bottom: none;
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &__title {
    margin-bottom: 0.5rem;
  }

  &__options {
    display: flex;
    flex-direction: column;

    &__item {
      padding-right: 3.25rem;
    }

    &__inputContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      .Button {
        margin-top: 0.7rem;
      }
    }

    &__card {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      border-radius: 8px;
      background-color: $white;
    }

    &__error {
      margin-top: 0.5rem;
    }
  }
}
