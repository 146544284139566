@import "../../../styles/theme.scss";

.ProgressBar {
  display: flex;
  flex-direction: column-reverse;
  gap: 0.5rem;
  overflow: hidden;
  width: 100%;

  &--vertical {
    flex-direction: row;
    margin-right: 2rem;
  }

  &__info {
    transition: transform 0.4s linear;
    font-weight: 800;
    font-size: 12px;
    @media (max-width: $sm) {
      display: none;
    }
  }
}
