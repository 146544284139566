@import "../../styles/theme.scss";

.Breadcrumbs {
  display: flex;
  padding: 1.5rem 0;
  z-index: 999;
  background-color: $defaultBackground;
  position: relative;

  &--hide {
    display: none;
  }

  &__item {
    display: flex;
    align-items: center;
    &__title {
      padding-left: 0.5rem;
    }
  }
}

.Breadcrumbs--hide + .Page {
  height: calc(100vh - $headerHeight);
}
