@import "../../styles/theme.scss";

.TrainingLinkCardContainer {
  height: $trainingModuleHeight;
  cursor: pointer;

  .TrainingLinkCard {
    height: calc($trainingModuleHeight - 2rem);
    background-color: white;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    &__text {
      margin: 1rem 0;
    }

    &__illustration {
      max-height: 165px;
    }

    .Button {
      align-self: flex-end;
    }
  }
}
