@import "../../styles/theme.scss";

.ScoreArc {
  text-align: center;
  width: 200px;

  &__info {
    margin: -5rem auto 0.5rem auto;

    &__score {
      width: fit-content;
      margin: auto;
      padding: 0 1rem 0.5rem 1rem;
      border-bottom: 1px solid $grey500;
    }
    &__label {
      display: flex;
      justify-content: center;
      padding-top: 0.5rem;

      .Icon {
        margin: auto 0 auto 4px;
      }
    }
  }

  &--small {
    width: 150px;
    .ScoreArc__info {
      margin: -4rem auto 0.25rem auto;
    }
  }

  &--large {
    width: 250px;
    .ScoreArc__info {
      margin: -6rem auto 1rem auto;
    }
  }
}
