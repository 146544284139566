@import "../../styles/theme.scss";

.InsightsSlide {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  min-height: 220px;

  &--compact {
    min-height: 110px;
  }

  &__accent {
    color: $roseOrange;
    font-weight: bold;
  }

  &__link {
    color: $roseOrange;
    font-weight: bold;
    margin-top: 1rem;
  }
}
