@import "../../styles/theme.scss";

.WorkplaceCultureWidget {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &--noSurvey {
    flex-direction: column-reverse;
  }
}
