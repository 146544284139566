@import "../../styles/theme.scss";

.InsightsWidget {
  margin-top: 1rem;
  padding: 1rem;
  height: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  background: radial-gradient(
    50% 50% at 50% 50%,
    $blue300 0%,
    transparent 100%
  );
}
