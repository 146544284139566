@import "../../styles/theme.scss";

.SurveyStatus {
  &__card {
    height: 48px;
    display: flex;
    margin-bottom: 1rem;
    align-items: center;
    .MuiCardActionArea-focusHighlight {
      background: transparent;
    }

    &--widget {
      background-color: $defaultBackground;

      &.SurveyStatus--clickable:hover {
        box-shadow: 2px 2px 10px rgb(0 0 0 / 0.2);
      }

      &.SurveyStatus--noSurvey {
        height: 48px;
        background-color: #d9fecf;
        border-radius: 32px;
      }
    }

    &--noSurvey {
      height: 100px;
    }

    &--noSurvey .SurveyStatus__content__title {
      &__status {
        padding: 16px 1px 16px 33px;
      }
    }

    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__title {
        display: flex;
        align-items: center;

        &__icon {
          min-width: 40px;
          margin-right: 0.5rem;
        }
      }

      &__info {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &__title {
          text-transform: uppercase;
          font-weight: bold;
        }
      }

      &__noSurveyInfo {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__title {
          margin-bottom: 0.5rem;
        }
      }
    }

    &__actions {
      &__icon {
        margin-right: 0.5rem;
      }

      &__edit {
        margin-left: -2rem;
      }

      &__setUpSurvey {
        white-space: nowrap;
      }
    }
  }
  &__modal {
    .Modal__container {
      max-width: 95%;
      height: fit-content;
      overflow-y: scroll;
    }

    .Modal__container__header {
      margin-left: 2.25rem;
      &__title {
        text-transform: uppercase;
        text-align: center;
      }
    }

    .Modal__container__body {
      justify-content: center;
    }
  }
}
